<template>
	<div>
		<div class="tw-flex tw-flex-row">
			<div class="tw-flex tw-flex-row tw-gap-[10px]">
				<v-select
				:items="['Sens 1', 'Sens 2']"
				v-model="direction"
				label="Choix du sens"
				outlined
				class="tw-w-[300px] tw-flex"
				hide-details
				dense
				/>
  
				<v-select
				:items="['PR + Abscisse', 'N° Carotte']"
				v-model="sortOrder"
				label="Mode de représentation"
				outlined
				class="tw-w-[300px] tw-flex"
				hide-details
				dense
				v-if="isLandmarkAndAbscissa"
				/>
  
				<div class="tw-flex tw-flex-row tw-gap-[10px]">
					<label
					dir="rtl"
					for="switch-DirMethodology"
					class="tw-text-[rgba(0,0,0,.6)] tw-select-none tw-h-[40px] tw-align-middle tw-leading-[40px] tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis"
					>
						Autre contraste
					</label>
  
					<v-switch
					id="switch-DirMethodology"
					v-model="colorBlindMode"
					class="methodology"
					/>
				</div>
			</div>
		</div>
  
		<v-simple-table id="scrollTable">
			<WaitingSlot
			v-if="!isDataLoaded"
			class="tw-h-full"
			/>
  
			<template
			v-if="isDataLoaded"
			v-slot:default
			>
				<thead>
					<tr>
						<th class="no-background">
							&nbsp;
						</th>
						<th class="no-background">
							&nbsp;
						</th>
						<th
						v-for="(header, key) in headers"
						:key="key+'header'"
						class="font-weight-bold text-center tw-w-[250px] tw-max-w-[300px]"
						colspan="5"
						style="font-size: 24px;"
						>
							{{ header }}
						</th>
					</tr>
					<tr>
						<th class="no-background">
							&nbsp;
						</th>
						<th class="no-background">
							&nbsp;
						</th>
						<th
						v-for="(subHeader, key) in subHeaders"
						:key="key+'subHeader'"
						class="font-weight-bold text-center no-background bordered"
						style="font-size: 15px"
						colspan="1"
						>
							<span>{{ subHeader }}</span>
						</th>
					</tr>
				</thead>
  
				<template v-if="direction === 'Sens 1'">
					<SynthesisTableVr
					v-if="vrCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:vrCores="vrCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
  
					<tr v-if="vrCores.length > 0">
&nbsp;
					</tr>
  
					<SynthesisTableVl
					v-if="vlCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:vlCores="vlCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
  
					<tr v-if="vlCores.length > 0">
&nbsp;
					</tr>
  
					<SynthesisTableBauVaVd
					v-if="braceCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:braceCores="braceCores"
					:bauCores="bauCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
  
					<tr v-if="braceCores.length > 0 || bauCores.length > 0">
&nbsp;
					</tr>
  
					<SynthesisTableBau
					v-if="bauCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:braceCores="braceCores"
					:bauCores="bauCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
  
					<tr v-if="braceCores.length > 0 || bauCores.length > 0">
&nbsp;
					</tr>
  
					<SynthesisTableOther
					v-if="otherCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:otherCores="otherCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
				</template>
  
				<template v-if="direction === 'Sens 2'">
					<SynthesisTableOther
					v-if="otherCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:otherCores="otherCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
  
					<tr 
					v-if="otherCores.length > 0"
					>
&nbsp;
					</tr>
  
					<SynthesisTableBau
					v-if="bauCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:braceCores="braceCores"
					:bauCores="bauCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
  
					<tr 
					v-if="bauCores.length > 0"
					>
&nbsp;
					</tr>
  
					<SynthesisTableBauVaVd
					v-if="braceCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:braceCores="braceCores"
					:bauCores="bauCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
  
					<tr v-if="braceCores.length > 0 || bauCores.length > 0">
&nbsp;
					</tr>
  
					<SynthesisTableVl
					v-if="vlCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:vlCores="vlCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
  
					<tr
					v-if="vlCores.length > 0"
					>
&nbsp;
					</tr>
  
					<SynthesisTableVr
					v-if="vrCores.length > 0"
					:isGreyBackground="isGreyBackground"
					:getCumul="getCumul"
					:getAsbestos="getAsbestos"
					:getPAH="getPAH"
					:getTH="getTH"
					:layersByCores="layersByCores"
					:vrCores="vrCores"
					:headers="headers"
					:sortOrder="sortOrder"
					:getMaterial="getMaterial"
					/>
				</template>
			</template>
		</v-simple-table>
	</div>
</template>
  
<script>
import SynthesisTableVr from "./SynthesisTableComponents/SynthesisTable.vr.vue";
import SynthesisTableVl from "./SynthesisTableComponents/SynthesisTable.vl.vue";
import SynthesisTableBauVaVd from "./SynthesisTableComponents/SynthesisTable.bau.va.vd.vue";
import SynthesisTableBau from "./SynthesisTableComponents/SynthesisTable.bau.vue";
import SynthesisTableOther from "./SynthesisTableComponents/SynthesisTable.other.vue";
  
export default {
	components: {
	  SynthesisTableVr,
	  SynthesisTableVl,
	  SynthesisTableBauVaVd,
	  SynthesisTableBau,
	  SynthesisTableOther
	},
	props: ["campaign"],
	data(){
	  return {
			cores: [],
			isDataLoaded: false,
			headers: [],
			subHeadersTemplate: [
				"Cumul", "Mat.", "Am.", "HAP", "HCT"
			],
			subHeaders: [],
			layersByCores: 0,
			vlCores: [],
			vrCores: [],
			bauCores: [],
			braceCores: [],
			otherCores: [],
			direction: "Sens 1",
			colorBlindMode: false,
			isLandmarkAndAbscissa: false,
			sortOrder: null,
	  };
	},
	watch: {
	  sortOrder(){
			this.setHeaders(); // Réapplique le tri chaque fois que l'ordre de tri est modifié
	  }
	},
	methods: {
		checkLandmarkAndAbscissa(){
			this.cores.forEach(core => {
				if(core.landmark !== null && core.abscissa !== null){
					this.isLandmarkAndAbscissa = true;
				}
			});
			this.isLandmarkAndAbscissa ? this.sortOrder = "PR + Abscisse" : this.sortOrder = "N° Carotte";
			this.setHeaders();
		},
	  	setHeaders(){
			let coresToSort = [...this.cores];
  
			if(this.sortOrder === "PR + Abscisse"){
				coresToSort.sort((a, b) => {
					if(a.landmark === b.landmark){
						return a.abscissa - b.abscissa;
					}
					return a.landmark - b.landmark;
				});

				// Utilisation d'un Set pour éviter les doublons de headers
				let uniqueHeaders = new Set();
				this.headers = coresToSort.map(core => {
					let header = core.landmark + (core.abscissa !== null ? "+" + core.abscissa : "");
					if(!uniqueHeaders.has(header)){
						uniqueHeaders.add(header);
						return header;
					}
					return null; // Filtrer les doublons
				}).filter(header => header !== null);

			}
			else if(this.sortOrder === "N° Carotte"){
				coresToSort.sort((a, b) => {
					const displayNameA = a.displayName || "";
					const displayNameB = b.displayName || "";
					if(displayNameA && displayNameB){
						return displayNameA.localeCompare(displayNameB);
					}
					return a.number - b.number;
				});
				this.headers = coresToSort.map(core => core.displayName ? core.displayName : core.number.toString());
			}
  
			this.subHeaders = Array.from({length: this.headers.length}).flatMap(() => this.subHeadersTemplate);

			let noLaneCores = coresToSort.filter(core => core.lane === null);

			if(noLaneCores.length === coresToSort.length){
				this.otherCores = coresToSort;
			}
			else {
  
				this.vrCores = coresToSort.filter(core => (core.lane === "VR" || core.direction === 2) && ![
					"BAU", "Bretelle", "Autre", "VL"
				].includes(core.lane));
				this.vlCores = coresToSort.filter(core => (core.lane === "VL" || core.direction === 1) && ![
					"BAU", "Bretelle", "Autre", "VR"
				].includes(core.lane));
				this.bauCores = coresToSort.filter(core => core.lane === "BAU");
				this.braceCores = coresToSort.filter(core => core.lane.startsWith("Bretelle"));
				this.otherCores = coresToSort.filter(core => core.lane === "Autre");
			}
	  	},
  
	  	getCumul(header, type, n){
			let cores = this.getCoreType(type);
  
			let core;
			if(this.sortOrder === "PR + Abscisse"){
		  		core = cores.find(core => core.landmark + "+" + core.abscissa === header);
			}
			else if(this.sortOrder === "N° Carotte"){
				core = cores.find(core => {
					if(core.displayName){
						return core.displayName === header;
					}
					else {
						return core.number.toString() === header;
					}
				});
			}
  
			if(core === undefined){
		  		return "";
			}
  
			let coreLayer = core.layersAndInterfaces.find(layerOrInterface => layerOrInterface.type === "layer" && layerOrInterface.number === n);
			if(coreLayer === undefined){
		  		return "";
			}
  
			let layers = core.layersAndInterfaces.filter(layerOrInterface => layerOrInterface.type === "layer" && layerOrInterface.number <= n);
  
			let cumulativeThickness = layers.reduce((total, layer) => total + layer.thickness, 0);
  
			return cumulativeThickness / 10;
	 	},
  
	  	getAsbestos(header, type, n){
			let cores = this.getCoreType(type);
  
			let core;
			if(this.sortOrder === "PR + Abscisse"){
		  		core = cores.find(core => core.landmark + "+" + core.abscissa === header);
			}
			else if(this.sortOrder === "N° Carotte"){
				core = cores.find(core => {
					if(core.displayName){
						return core.displayName === header;
					}
					else {
						return core.number.toString() === header;
					}
				});
			}
  
			if(core === undefined){
		  		return "transparent";
			}
  
			let coreLayer = core.layersAndInterfaces.find(layerOrInterface => layerOrInterface.type === "layer" && layerOrInterface.number === n);
  
			return coreLayer !== undefined ? coreLayer.asbestosResult?.containsAsbestos ? "red" : this.colorBlindMode ? "yellow" : "green" : "transparent";
	  	},
  
	  	getPAH(header, type, n){
			let cores = this.getCoreType(type);
  
			let core;
			if(this.sortOrder === "PR + Abscisse"){
		  		core = cores.find(core => core.landmark + "+" + core.abscissa === header);
			}
			else if(this.sortOrder === "N° Carotte"){
				core = cores.find(core => {
					if(core.displayName){
						return core.displayName === header;
					}
					else {
						return core.number.toString() === header;
					}
				});
			}

  
			if(core === undefined){
		  		return "transparent";
			}
  
			let coreLayer = core.layersAndInterfaces.find(layerOrInterface => layerOrInterface.type === "layer" && layerOrInterface.number === n);
			if(coreLayer === undefined || coreLayer.PAHResult === null){
		  		return "transparent";
			}
  
			let color;
  
			if(coreLayer.PAHResult.sum < 50){
				this.colorBlindMode ? color = "yellow" : color = "green";
			}
			else if(coreLayer.PAHResult.sum >= 50 && coreLayer.PAHResult.sum < 500){
		  		this.colorBlindMode ? color = "blue" : color = "yellow";
			}
			else if(coreLayer.PAHResult.sum >= 500 && coreLayer.PAHResult.sum < 1000){
		  		color = "red";
			}
			else {
		  		color = "black";
			}
  
			return color;
	 	 },
  
	  	getTH(header, type, n){
			let cores = this.getCoreType(type);
  
			let core;
			if(this.sortOrder === "PR + Abscisse"){
		  		core = cores.find(core => core.landmark + "+" + core.abscissa === header);
			}
			else if(this.sortOrder === "N° Carotte"){
				core = cores.find(core => {
					if(core.displayName){
						return core.displayName === header;
					}
					else {
						return core.number.toString() === header;
					}
				});
			}

  
			if(core === undefined){
		  		return "";
			}
  
			let coreLayer = core.layersAndInterfaces.find(layerOrInterface => layerOrInterface.type === "layer" && layerOrInterface.number === n);
  
			if(coreLayer === undefined || coreLayer.THResult === null){
		  		return "";
			}
  
			return coreLayer.THResult?.sum < 500 ? "" : "O";
	  },

	  getMaterial(header, type, n){
			let cores = this.getCoreType(type);
  
			let core;
			if(this.sortOrder === "PR + Abscisse"){
		  		core = cores.find(core => core.landmark + "+" + core.abscissa === header);
			}
			else if(this.sortOrder === "N° Carotte"){
				core = cores.find(core => {
					if(core.displayName){
						return core.displayName === header;
					}
					else {
						return core.number.toString() === header;
					}
				});
			}
  
			if(core === undefined){
		  		return "";
			}
  
			let coreLayer = core.layersAndInterfaces.find(layerOrInterface => layerOrInterface.type === "layer" && layerOrInterface.number === n);
  
			if(coreLayer === undefined || coreLayer.material === null){
		  		return "";
			}
  
			return coreLayer.material?.initialism;
	  },

	  isGreyBackground(header, type, n){
			let cores = this.getCoreType(type);
  
			let core;
			if(this.sortOrder === "PR + Abscisse"){
		  		core = cores.find(core => core.landmark + "+" + core.abscissa === header);
			}
			else if(this.sortOrder === "N° Carotte"){
				core = cores.find(core => {
					if(core.displayName){
						return core.displayName === header;
					}
					else {
						return core.number.toString() === header;
					}
				});
			}

  
			if(core === undefined){
		  		return "transparent";
			}
			let coreLayer = core.layersAndInterfaces.find(layerOrInterface => layerOrInterface.type === "layer" && layerOrInterface.number === n);
			let lastCoreLayer = core.layersAndInterfaces.find(layerOrInterface => layerOrInterface.type === "layer" && layerOrInterface.number === n - 1);
			if(coreLayer === undefined && lastCoreLayer !== undefined){
		  		return "grey";
			}
			else if(lastCoreLayer === undefined){
		  		return "transparent";
			}
	  	},
  
	  	getCoreType(type){
			switch (type){
				case "VR":
					return this.vrCores;
				case "VL":
					return this.vlCores;
				case "BAU":
					return this.bauCores;
				case "Bretelle":
				case "Bretelle 1":
				case "Bretelle 2":
				case "Bretelle 3":
				case "Bretelle 4":
				case "Bretelle 5":
				case "Bretelle 6":
				case "Bretelle 7":
				case "Bretelle 8":
					return this.braceCores;
				case "Autre":
					return this.otherCores;
			}
		},
	  setNumberOfLayers(){
			let max = 0;
			let numberOfLayers;
			this.cores.forEach(core =>  {
				numberOfLayers = core.layersAndInterfaces.filter(e => e.type === "layer").length;
				if(max < numberOfLayers){
					max = numberOfLayers;
				}
			});
			this.layersByCores = max + 1;
		},
	},
	mounted(){
	  this.$api.cores.findByCampaign(this.campaign.id, true).then(data => {
			this.cores = data;
			this.checkLandmarkAndAbscissa();
			this.setHeaders();
			this.setNumberOfLayers();
			this.isDataLoaded = true;
	  });
	}
};
</script>
  
  <style lang="scss" scoped>
  .bordered {
	border: thin solid rgba(0, 0, 0, 0.12);
  }
  td {
	border: solid 1px lightgrey;
	min-width: 50px;
  }
  th {
	background-color: #2b0803;
	color: white !important;
	font-size: 24px;
	border: 1px solid white;
  }
  tr > td:first-child {
	background-color: #ebe7e7;
	font-weight: bold;
  }
  table {
	border-radius: 4px !important;
  }
  
  th span {
	-ms-writing-mode: tb-rl;
	-webkit-writing-mode: vertical-rl;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	white-space: nowrap;
  }
  
  td span {
	-ms-writing-mode: tb-rl;
	-webkit-writing-mode: vertical-rl;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	white-space: nowrap;
  }
  
  .no-background {
	background-color: white !important;
	color: black !important;
  }
  
  .coreName {
	border-right: 2px solid black;
	border-left: 2px solid black;
	font-size: 24px !important;
  }
  
  .methodology {
	position: relative;
	bottom: 12px;
  }
  
  .methodology ::v-deep .v-input__slot {
	flex-direction: row-reverse;
	justify-content: flex-end;
  }
  
  </style>
  
